<template>
  <main class="main"> 
    <div class="about-area p-80">
      <div class="about">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="about-title">
                <h2>{{$t('about.title')}}</h2>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="about-img">
                <img src="../assets/img/ja.jpg" alt="">
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="about-me">
                <h6><span>{{$t('about.name')}}</span> Jaroslav Petráš</h6>
                <h6><span>{{$t('about.address')}}</span> Michalovská 17, 040 11 Košice</h6>
                <h6><span>IČO:</span> 54097851</h6>
                <h6><span>DIČ:</span> 1120735264</h6>
                <h6><span>{{$t('about.email')}}</span> <a href="mailto:info@webux.sk">info@webux.sk</a></h6>
                <h6><span>{{$t('about.phone')}}</span> <a href="tel:+421949539363">+421 949 539 363</a></h6>
                <h6><span>{{$t('about.about')}}</span></h6>
                <p>{{$t('about.description')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setDocumentTitle from '../util'
export default {
  name: 'About',
  mounted(){
      this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return
        }
        setDocumentTitle(this.$t("navigation.about"))
      },
      { immediate: true }
    )
    }
}
</script>
<style lang="scss">
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.about-area{
  @include views-general;
  text-align: left;
  .about{
    @include views;
    .about-title{
      @include section-title;
    }
    .about-img {
      img{
        width: 100%;
        height: auto;
        @media #{$mobile}{
          margin-bottom: 16px;
        }
      }
    }
    .about-me{
      padding-right: 60px;
      @media #{$very_small}{
        padding-right: 0px;
    }
      h6{
        span{
          font-weight: 700;
        }
        a{
          color: $primary;
          &:hover{
            color: $secondary;
            transition: 0.35s ease;
          }
        }
      }
    }
  }
}
</style>